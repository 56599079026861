import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import $ from 'jquery'
const PopularSearchDetails = (props) => {
    
    // console.log("&&&&&&", props)

    return (<React.Fragment>
        {props.propertydetails && props.propertydetails.map((property) => {
        var typeText = ''
        var URL = ''
        var area = 'London'

        // Check if property build is array. if yes getting first item here to avoid page break
        if (property.building && (typeof property.building === 'object'))
        property.building = property.building[0]

        var ptypeURL= '/type-'+property.building.toLowerCase().replace(" ","-").replace(/%20/g, "-")

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }  
        if(property.search_type == 'sales') {
            typeText= 'for Sale'
            URL          = '/property/for-sale/'
        }
        if(property.search_type == 'lettings') {
            typeText= 'to Rent'
            URL          = '/property/to-rent/'
        }

        return(
        <Row>
            <Col md={6} lg={4}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-${property.address.address1.toLowerCase().replace(" ","-").replace(/%20/g, "-")}/type-property/`}>Property {typeText} in {property.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${property.address.address1.toLowerCase().replace(" ","-").replace(/%20/g, "-")}/type-house/`}>Houses {typeText} in {property.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${property.address.address1.toLowerCase().replace(" ","-").replace(/%20/g, "-")}/type-flat/`}>Flats {typeText} in {property.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${property.address.address1.toLowerCase().replace(" ","-").replace(/%20/g, "-")}/type-bungalows/`}>Bungalows {typeText} in {property.address.address1}</Link></li>
            <li><Link className="footer_scroll_top" to="/about-us/area-guide/">{area} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to="/value-my-property/property-valuation/">Property Valuation North London</Link></li>
            </ul>
            </Col>
            <Col md={6} lg={4}>
                <ul>
                <li><Link className="footer_scroll_top"  to={`${URL}in-hertfordshire${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Hertfordshire</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-palmers-green${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Palmers green</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-harringay${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Harringay</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-wichmore-hill${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Wichmore hill</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-crouch%-end${ptypeURL}/`}>{capitalizeFirstLetter(property.building)} {typeText} in Crouch end</Link></li>
                </ul>
            </Col>
        </Row>
        )
        })}
    </React.Fragment>)

}

export default PopularSearchDetails;