import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Col, Row } from "react-bootstrap";
import ContactBox from '@components/contact-box/contact-box';
import Img from 'gatsby-image';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'

export default function Header() {
  return (
    <StaticQuery
      query={graphql`
        query BurgerMemberQuery {
        strapiGlobalConfig {
          Burger_Team_Member_Above_Title
          Burger_Team_Member_Name
          Burger_Team_Member_Phone
          Burger_Team_Member_Calendly
          Burger_Team_Member_Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
        }
      `}
      render={data => (
          <div className="right-side">
          &nbsp;
          </div>
      )}
    />
  )
}
