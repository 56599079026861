import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const TextAreaField = ({name, rows, placeholder, required, handlechange, fieldClass, label, grpmd}) => (
  <Form.Group className="area-field" controlId="validation{name}">
    { label
      ? <Form.Label>{label}{required ? '*' : ''}</Form.Label>
      : ''
    }
    <Form.Control
      className={fieldClass}
      name={name}
      required={required}
      as="textarea"
      rows={rows}
      onChange={handlechange}
      placeholder={placeholder} 
    />
    {placeholder?
          <label className="plclabel">{placeholder}</label>
          :""
    }
  </Form.Group>
);

export default TextAreaField;