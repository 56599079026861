import { Link } from 'gatsby';
import React, {useEffect} from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';
import CookieConsent from "react-cookie-consent";
import PopularSearchDetails from "../popular-search-details";
import PopularSearchStaticDetails from "../popular-search-static-details";
import PopularSearchDynamic from "../popular-search-dynamic";
import PopularSearch from "../popular-search-static";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import $ from "jquery";
import CustomScripts from "./custom-scripts";
import GetURL from "../../components/getlink";
/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import { Twitter, Facebook, Instagram, Linkedin, ArrowDown, StarBerry, YouTube } from '@components/icon/icon'

const Footer = (props) => {
    
    // console.log ("&&&&&&", props.searchtype)
    
    const Activesearch = () => {
        $(".open-popular-search").toggleClass("searchopen");
    }
    return <React.Fragment>
        <CustomScripts/>
        <footer className="footer">
            <Container>
                <div className="footer__top">
                    <Accordion>
                        <Accordion.Toggle onClick={()=> Activesearch()} className="open-popular-search" variant="link" eventKey="1">Popular Property Searches <span><ArrowDown /></span></Accordion.Toggle>
                        
                        {/* <Accordion.Collapse eventKey="1">
                            <Row>
                                <Col md={6} lg={3}>
                                    <ul>
                                        <li><Link to="/property-search/sales/in-Hertfordshire">Property for Sale in Hertfordshire</Link></li>
                                        <li><Link to="/property-search/sales/in-Palmers%20Green">Property for Sale in Palmers Green</Link></li>
                                        <li><Link to="/property-search/sales/in-Harringay">Property for Sale in Harringay</Link></li>
                                        <li><Link to="/property-search/sales/in-Wichmore%20hill">Property for Sale in Wichmore hill</Link></li>
                                        <li><Link to="/property-search/sales/in-Crouch%20end">Property for Sale in Crouch end</Link></li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3}>
                                    <ul>
                                        <li><Link to="/property-search/rentals/in-Hertfordshire">Property to Rent in Hertfordshire</Link></li>
                                        <li><Link to="/property-search/rentals/in-Palmers%20green">Property to Rent in Palmers green</Link></li>
                                        <li><Link to="/property-search/rentals/in-Harringay">Property to Rent in Harringay</Link></li>
                                        <li><Link to="/property-search/rentals/in-Wichmore%20hill">Property to Rent in Wichmore hill</Link></li>
                                        <li><Link to="/property-search/rentals/in-Crouch%20end">Property to Rent in Crouch end</Link></li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3}>
                                    <ul>
                                        <li><Link to="/contact/our-offices/cockfosters">Estate Agents in Cockfosters</Link></li>
                                        <li><Link to="/contact/our-offices/crouch-end">Estate Agents in Crouch End</Link></li>
                                        <li><Link to="/contact/our-offices/harringay">Estate Agents in Harringay</Link></li>
                                        <li><Link to="/contact/our-offices/highbury">Estate Agents in Highbury</Link></li>
                                        <li><Link to="/contact/our-offices/palmers-green">Estate Agents in Palmers Green</Link></li>
                                    </ul>
                                </Col>
                                <Col md={6} lg={3}>
                                    <ul>
                                        <li><Link to="/contact/our-offices/">Estate Agents in North London</Link></li>
                                        <li><Link to="/value-my-property/property-valuation/">Property Valuation North London</Link></li>
                                        <li><Link to="/about">About Anthony Pepe</Link></li>
                                        <li><Link to="/about/area-guide/">North London Area Guide</Link></li>
                                        <li><Link to="/find-a-property/new-homes">New Homes in North London</Link></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Accordion.Collapse> */}

                        <Accordion.Collapse eventKey="1">
                            <section className="popular-searchlist">
                                {props.popularSearch == 'propertydetails' &&
                                    <PopularSearchDetails propertydetails={props.propertydetails} />
                                }
                                {props.popularSearch == 'staticdetails' &&
                                    <PopularSearchStaticDetails office={props.office} area={props.area} guides={props.guides} />
                                }
                                {props.popularSearch == 'propertyresults' ?
                                    <PopularSearchDynamic searchtype={props.searchtype} />
                                    : <PopularSearch popularSearch={props.popularSearch} />
                                }
                            </section>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
                <div className="footer_bottom">
                    <Row>
                        <Col md="6" lg="4" className="footer-menu">
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.footerDetails.Footer_Office_Address_Left}/>
                        </Col>
                        <Col md="6" lg="4" className="footer-menu">
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.footerDetails.Footer_Office_Address_Right}/>
                        </Col>
                        <Col md="12" lg="4">
                            <div className="socials">
                                <ul>
                                    <li><a href={props.footerDetails.Twitter_Link} target="_blank"><span>Twitter</span> <Twitter /></a></li>
                                    <li><a href={props.footerDetails.Facebook_Link} target="_blank"><span>Facebook</span> <Facebook /></a></li>
                                    <li><a href={props.footerDetails.Instagram_Link} target="_blank"><span>Instagram</span> <Instagram /></a></li>
                                    <li><a href={props.footerDetails.Linkedin_Link} target="_blank"><span>Linkedin</span> <Linkedin /></a></li>
                                    <li className="socialyoutube"><a href="https://www.youtube.com/channel/UC_kPP2Zb-DR2THVz3tPLAtA" target="_blank"><span>Youtube</span> <YouTube /></a></li>
                                </ul>
                            </div>
                            <p className="copy">
                                <span>
                                    {props.FooterData.Footer_CTA.length > 0 &&
                                    props.FooterData.Footer_CTA.map(f => (
                                        <GetURL label={f.label} link={f.Menu_item.URL} Secondary_URL={f.Menu_item.Secondary_URL} />
                                    ))}
                                </span><br />
                                {props.FooterData.Company_Reg_No}<br />
                                © {new Date().getFullYear()} Anthony Pepe. All RightsReserved.
                            </p>
                            {props.FooterData.Footer_Logo.length > 0 &&
                                <div className="logo-block">
                                    {props.FooterData.Footer_Logo.map((c)=>(
                                        c.Client_Url ?
                                        <a href={c.Client_Url} target="_blank" >
                                            <img loading='lazy' src={`${process.env.GATSBY_SITE_URL}${c.Client_Image.publicURL}`} alt={c.label}/>
                                        </a>
                                        :  <img loading='lazy' src={`${process.env.GATSBY_SITE_URL}${c.Client_Image.publicURL}`} alt={c.label}/>
                                    ))}
                                </div>
                            }
                            <p className="copy with-logo-btm">Site by <a href="https://starberry.tv/" target="_blank"> <StarBerry /></a></p>
                        </Col>
                    </Row>
                </div>
            </Container>

            {/* <CookieConsent
                disableStyles={true}
                buttonClasses="btn btn-primary"
                containerClasses="cookie-popup d-lg-flex d-block"
                contentClasses=""
                buttonClasses="btn btn-link gotit"
                buttonText="Accept Cookies"

            >
                <p className="mb-0 text-center">We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy/">Cookie Policy</Link>.</p>
            </CookieConsent> */}
            
        </footer>
        </React.Fragment>
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
