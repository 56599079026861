import React, { useContext, useEffect } from 'react';
import classNames from 'classnames'
import { Link, useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import "animate.css/animate.min.css";
import Searchpopup from '../search-overlay/search-overlay';
import { useMatch } from "@reach/router"

/**
* Assets
*/

import './styles/_index.scss';
import $ from "jquery";
/**
* Components
*/
import { Logo, LogoElement } from '@components/icon/icon';
import ContactBox from '@components/contact-box/contact-box';
import GETBurgerMember from './get-nav-member'
function ContextAwareToggle({ children, eventKey, callback }) {
useEffect(() => {
    $(".open-search-overlay").click(function(){
        $('.triggersearchoverlay').click();
    });
$(".thrid-level").each(function () {
var thirdlevel = $(this).find("li");
//console.log('aaaaaaaaa',thirdlevel.length)
var ifthirdlevel = thirdlevel.length
if (ifthirdlevel > 0) {
$(this).parent().addClass('has-dropdown');
}
});
//toggle the component with class thrid-level
$(".open-third-level").click(function (e) {
var checkactive = $(this)[0].className;
if (checkactive.indexOf('active') != -1) {
$('.open-third-level').removeClass('active');
$('li.second-level.has-dropdown .thrid-level').stop(true, true).slideUp();
$('li.second-level.has-dropdown .open-third-level').find('.plusminus').text('+');
e.stopPropagation();
} else {
$('.open-third-level').removeClass('active');
$('li.second-level.has-dropdown .open-third-level').find('.plusminus').text('+');
$(this).toggleClass("active");
$(this).next('.thrid-level').slideDown();
$(this).find('.plusminus').text('-');
e.stopPropagation();
}
});
}, []);

const currentEventKey = useContext(AccordionContext);

const decoratedOnClick = useAccordionToggle(
eventKey,
() => callback && callback(eventKey),
);

const isCurrentEventKey = currentEventKey === eventKey;

return (
<button
type="button"
className={classNames('toggle-button', { 'active': isCurrentEventKey })}
onClick={decoratedOnClick}
>toggle-button</button>
);
}

const ModalNav = (props) => {
  const ifhome = useMatch('/');

  function searchOverlay(e) {
    $("body")
        .addClass("modal-open")
        .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }

const active = props.active;
const data = useStaticQuery(graphql`
query {
avatar: file(relativePath: { eq: "avatar.png" }) {
childImageSharp {
fluid(maxWidth: 180) {
...GatsbyImageSharpFluid
}
}
}
}
`);

useEffect(() => {
return function () {
console.log(1)
document.querySelectorAll('body,html').forEach(item => item.classList.remove('overflow'))
}
}, [])
return (
<>
{!ifhome &&
<Searchpopup />
}
<a href="javascript:;" className={props.class} onClick={searchOverlay}>{props.label}</a>
</>
)
}

export default ModalNav
