import React, { useState,useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import $ from 'jquery'
const PopularSearchDynamic = (props) => {
  //Get values from refine search
	const [ptype,setPtype] = useState(false)
  const [searchbed,setsearchbed] = useState(false)
 

  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = 'London'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText=''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }  
  if(props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL          = '/property/for-sale/'
  }
  if(props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL          = '/property/to-rent/'
  }
  if(props.searchPtype) {
    ptypeURL   = '/type-'+props.searchPtype.toLowerCase().replace(" ","-").replace(/%20/g, "-")
    typeText     = capitalizeFirstLetter(props.searchPtype)
  }
  if(props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield+' Bedroom '+typeText
    bedURL   = '/'+props.searchBedroomfield+'-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
    searcharea = props.Searcharea.areas
    searchareaText = props.Searcharea.areas.replace(/%20/g, " ")
    searcharea = searcharea.toLowerCase().replace(" ","-").replace(/%20/g, "-")
  }
  return (<React.Fragment>
        <Row>
          {props.searchBedroomfield ?
          <Col md={6} lg={4}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-hertfordshire${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Hertfordshire</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-palmers-green${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Palmers green</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-harringay${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Harringay</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-wichmore-hill${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Wichmore hill</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-crouch-end${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Crouch end</Link></li>
          </ul>
          </Col>
          :<Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-hertfordshire${ptypeURL}/`}>{typeText} {searchaction} in Hertfordshire</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-palmers-green${ptypeURL}/`}>{typeText} {searchaction} in Palmers green</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-harringay${ptypeURL}/`}>{typeText} {searchaction} in Harringay</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-wichmore-hill${ptypeURL}/`}>{typeText} {searchaction} in Wichmore hill</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-crouch-end${ptypeURL}/`}>{typeText} {searchaction} in Crouch end</Link></li>
          </ul>
          </Col>
          }
          {searcharea ?
          <Col md={6} lg={3}>
          <ul>
           {ptype && searchbed && 
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/`}>Property {searchaction} in {searchareaText}</Link></li>
            }
            {ptype && searcharea && 
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/`}>Property {searchaction} in {searchareaText}</Link></li>
            }
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-house/`}>Houses {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-flat/`}>Flats {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-maisonette/`}>Maisonette {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to="/about/area-guide/">{area} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to="/value-my-property/property-valuation/">Property Valuation North London
</Link></li>
          </ul>
          </Col>:
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-house/`}>Houses {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-flat/`}>Flats {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-london/type-maisonette/`}>Maisonette {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to="/about/area-guide/">{area} Area Guides</Link></li>
            <li><Link className="footer_scroll_top" to="/value-my-property/property-valuation/">Property Valuation North London
</Link></li>
          </ul>
          </Col>  
          }       
        </Row>



  </React.Fragment>)

}

export default PopularSearchDynamic;