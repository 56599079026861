import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link, navigate }  from "gatsby"
import $ from "jquery";
import Select from "react-select";
import { Search} from '../icon/icon';
import ScrollAnimation from 'react-animate-on-scroll';

import "./assets/styles/_index.scss"

const SearchOverlay = (props) => {

    function closeSearchOverlay(e) {
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }

    // $('.search_buy').click(function(){
    //     var conceptName = $('#aioConceptName :selected').val()+'/sales';
    //     navigate('/contact/general-enquiry?contact=officecontact')

    // })
    const SearchFunsales = () => {
        
        var conceptName = $('#aioConceptName :selected').val()+'for-sale';
        var searchinput = $('#searchinput').val();
        var searchURL = ''
        if(searchinput) {
            searchURL = conceptName+'/in-'+searchinput
        } else {
            searchURL = conceptName+'/in-london'
        }
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
        navigate(searchURL+'/')
        $("html, body").scrollTop(0);

    }
    
    const SearchFunrentals = () => {
        
        var conceptName = $('#aioConceptName :selected').val()+'to-rent';
        var searchinput = $('#searchinput').val();
        var searchURL = ''
        if(searchinput) {
            searchURL = conceptName+'/in-'+searchinput
        } else {
            searchURL = conceptName+'/in-london'
        }
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
        navigate(searchURL+'/')
        $("html, body").scrollTop(0);
    }
    
    

      
    return(
        <>

            <div className="search-overlay">
                <a hre="javascript:;" onClick={closeSearchOverlay}>
                    <div className="icon-search-close">
                    <button className="close-nav">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>    
                    </div>
                </a>
                <Container className="property-search-overlay_vertical_align">
                <ScrollAnimation offset={0} animateIn="animate__slideInDown" animateOnce={true}>                                  
                    <Row>
                        <Col lg={10}>
                            <Tabs  id="uncontrolled-tab-example" className="search-tab">
                                {/* <Tab eventKey="sitesearch" title="Site Search">
                                    <input type="text" placeholder="Search the website" name="search" className="form-control search-overlay-input" />
                                </Tab> */}
                                <Tab defaultActiveKey="sitesearch" eventKey="propertysearch" title="Find a Property">
                                    <div className="input-group mb-3 property-search-overlay">
                                        <select className="search-select" id="aioConceptName">
                                            <option value="/property/">Residential</option>
                                        </select>
                                        <div className="input-group-prepend property-search-width">
                                            <input id="searchinput" type="text" placeholder="Street, area or postcode" name="search" className="form-control search-property-input" />
                                        </div>
                                        <div className="property-search-btn_wrapper">
                                            <a href="javascript:;" onClick={()=> SearchFunsales()} className="btn search_buy">Buy</a>
                                            <a href="javascript:;" onClick={()=> SearchFunrentals()} className="btn search_rent">Rent</a>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    </ScrollAnimation>
                </Container>
            </div>
        </>
    )
}

export default SearchOverlay