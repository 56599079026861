import React,{useEffect,useState} from 'react';
import { Col, Row } from "react-bootstrap";
import Img from 'gatsby-image';
import ImgBox from '@components/img-box/img-box'
import MemberForm from "@components/forms/member-form"
import Modal from 'react-bootstrap/Modal'

/**
 * Assets
 */

import './styles/_index.scss';

const ContactBox = ({image, teamimage, phone, link, email, name, position, linkText}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [teamid, setteamid] = useState(false);

  const handleShow = (event) => {
    setteamid(event);
    setShow(true);
}

let AOS;
useEffect(() => {
  const AOS = require("aos");
  AOS.init({
    once: true,
    disable: 'mobile'
  });
}, [])

    return (
        <div className="contact-box" data-aos="fade-right"  data-aos-offset="0">
            <Row>
                <Col xs="auto">
                {teamimage !=null &&
                    <>
                    <ImgBox image={teamimage}></ImgBox>

                    </>
                }
                {teamimage ==null &&
                    <Img fluid={image} alt="avatar" />
                }
                </Col>
                <Col>
                    <h5>{name}</h5>
                    {position ? <h6>{position}</h6> : ''}
                    <div className="links">
                        {phone && 
                        <>
                        <a href={`tel:${phone}`}>{phone}</a> <span> / </span>
                        </>
                         }
                         {link ?
                        <a href={`${link}/`}>{linkText}</a> :
                        <a href="javascript:;" onClick={ () => handleShow(email)}>Email</a>
                         }
                        </div>
                        {teamid && teamid == email &&
      <>
        <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form">

          <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100">CONTACT - {name}
            </Modal.Title>
              </Modal.Header>
        <Modal.Body>
        <MemberForm to_email_id={email} formName={`Contact Person`} />
        </Modal.Body>
      </Modal>
      </>
        }
                </Col>
            </Row>
        </div>
    )
}

export default ContactBox;
