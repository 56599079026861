import React from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "gatsby";
import { Logo, AreaGuideArrow } from '../components/icon/icon';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';

const STATIC_POPULAR_SEARCH = gql`
query GetstaticPopularSearch{
    globalConfig {
        Popular_Search_Static_Common
        Popular_Search_Static_Sales
        Popular_Search_Static_Rentals
    }
}
`;

function PopularSearchStatic(props) {
    
    var popularSearchtype = props.popularSearch
    const { loading, error, data } = useQuery(STATIC_POPULAR_SEARCH);
      if (loading) return (
        <div className="static-details gql-loading-screen d-none">
            <Container>
            <Logo />
            <div className="gql-loading">Loading ...</div>
            </Container>
        </div>);
            return (
            <>
            {popularSearchtype=='Popular_Search_Static_Common' ?
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={data?.globalConfig?.Popular_Search_Static_Common}/>
            
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Sales' ?
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={data.globalConfig.Popular_Search_Static_Sales}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Rentals' ?
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={data.globalConfig.Popular_Search_Static_Rentals}/>
            : ''
            }
            </>
        )
}

export default PopularSearchStatic