/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import "@styles/main.scss";

import Header from '@components/header/header';
import Footer from '@components/footer/footer';

const Layout = (props) => {


  const data = useStaticQuery(graphql`
    query SiteTitleQuery {  
      site {
        siteMetadata {
          title
        }
      }
      strapiFooterData {
        Company_Reg_No
        Footer_Logo {
          label
          Client_Url
          Client_Image {
            publicURL
          }
        }
        Footer_CTA {
          Menu_item {
            URL
            Secondary_URL
          }
          label
        }
      }
      strapiGlobalConfig {
          Footer_Office_Address_Left
          Footer_Office_Address_Right
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Facebook_Link
          Popular_Search_Static_Rentals
          Popular_Search_Static_Common
          Popular_Search_Static_Sales
          Get_In_Touch_Content
      }
      allStrapiTopNavigations(filter: {Sort: {}}, sort: {order: ASC, fields: Sort}) {
        edges {
        node {
          Menu_Name
          Select_Menu {
            id
            URL
            Select_Article
            Secondary_URL
          }
        }
      }
    }
   
    }
  `);
  return (
    <div id="main-wrapper" className={data.site.siteMetadata === 'Home' ? 'homepage' : 'inner-page'}>
      <Header headertype={props.headertype} Topmenus={data.allStrapiTopNavigations} />
      <main>{props.children}</main>
      <Footer FooterData={data.strapiFooterData} footerDetails={data.strapiGlobalConfig} popularSearch={props.popularSearch} area={props.area} office={props.office} guides={props.guides} searchtype={props.searchtype} propertydetails={props.propertydetails} typeText={props.typeText} bedtypeText={props.bedtypeText} searchbed={props.searchbed} ptype={props.ptype} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
