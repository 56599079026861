import React from 'react'
import { Form } from "react-bootstrap"
function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}
const RadioboxField = ({fieldClass, name, value, required, placeholder, handlechange, checked, lastchild, step}) => (  

      <div className={`form-group radio-wrap ${ lastchild } ${ step }`}>
      <label className="radio-label">
      <input type="radio" id={name+checked} name={name} value={value} onChange={handlechange}  />
      <span className="radio-ico"></span>
      <span className="radio-label-wrap">
      {value}
      </span>
      </label>
  </div>
);

export default RadioboxField;