import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import $ from 'jquery'
const PopularSearchStaticDetails = (props) => {

    // console.log("&&&&&&", props)
    
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }  
    var SaleURL          = '/property/for-sale/'
    var RentURL          = '/property/to-rent/'
    var searcharea = capitalizeFirstLetter(props.area && props.area.replace(/-/g, ' '))
     var searchareaURL = props?.area.toLowerCase().replace(" ","-").replace(/%20/g, "-")

    return (<React.Fragment>
        
        <Row>
            <Col md={6} lg={4}>
            <ul>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-${searchareaURL}/`}>Property for Sale in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-${searchareaURL}/type-house/`}>Houses for Sale in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-${searchareaURL}/type-flat/`}>Flats for Sale in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-${searchareaURL}/type-maisonette/`}>Maisonette for Sale in {searcharea}</Link></li>
                {props.guides ?
                <li><Link className="footer_scroll_top" to={`/about/area-guide/${props.guides.URL}/`}>{props.guides.Title} Area Guide</Link></li>
                :
                <li><Link className="footer_scroll_top" to="/about/area-guide/">London Area Guide</Link></li>
                }
            {props.office && props.office.map((office) => {
                return<>
                <li><Link className="footer_scroll_top" to={`/contact/our-offices/${office.URL}/`}>Estate agents in {office.Area}</Link></li>
                </>
            })}
            {props.office && props.office.length == 0 && props.office=='' &&
            <li><Link className="footer_scroll_top" to="/contact/our-offices/">Estate agents in london</Link></li>
            }

            </ul>
            </Col>
            <Col md={6} lg={4}>
            <ul>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-${searchareaURL}/`}>Property to Rent in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-${searchareaURL}/type-house/`}>Houses to Rent in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-${searchareaURL}/type-flat/`}>Flats to Rent in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-${searchareaURL}/type-maisonette/`}>Maisonette to Rent in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to="/value-my-property/property-valuation/">Property Valuation London</Link></li>
            </ul>
            </Col>
        </Row>
  
    </React.Fragment>)

}

export default PopularSearchStaticDetails;