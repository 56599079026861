import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames'
import { Link, useStaticQuery, graphql } from "gatsby";
import { Nav, Container, Col, Row, Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";
import "animate.css/animate.min.css";
import Searchpopup from '../search-overlay/search-overlay';

/**
* Assets
*/

import './styles/_index.scss';
import $ from "jquery";
/**
* Components
*/
import { Logo, LogoElement } from '@components/icon/icon';
import ContactBox from '@components/contact-box/contact-box';
import GETBurgerMember from './get-nav-member'
import MenuBG from "../../../src/images/menu-bg.png";
import Img from 'gatsby-image';
function ContextAwareToggle({ children, eventKey, callback }) {
useEffect(() => {
    $(".open-search-overlay").click(function(){
        $('.triggersearchoverlay').click();
    });
$(".thrid-level").each(function () {
var thirdlevel = $(this).find("li");
//console.log('aaaaaaaaa',thirdlevel.length)
var ifthirdlevel = thirdlevel.length
if (ifthirdlevel > 0) {
$(this).parent().addClass('has-dropdown');
}
});
//toggle the component with class thrid-level
$(".open-third-level").click(function (e) {
var checkactive = $(this)[0].className;
if (checkactive.indexOf('active') != -1) {
$('.open-third-level').removeClass('active');
$('li.second-level.has-dropdown .thrid-level').stop(true, true).slideUp();
$('li.second-level.has-dropdown .open-third-level').find('.plusminus').text('+');
e.stopPropagation();
} else {
$('.open-third-level').removeClass('active');
$('li.second-level.has-dropdown .open-third-level').find('.plusminus').text('+');
$(this).toggleClass("active");
$(this).next('.thrid-level').slideDown();
$(this).find('.plusminus').text('-');
e.stopPropagation();
}
});
}, []);

const currentEventKey = useContext(AccordionContext);

const decoratedOnClick = useAccordionToggle(
eventKey,
() => callback && callback(eventKey),
);

const isCurrentEventKey = currentEventKey === eventKey;

return (
<button
type="button"
className={classNames('toggle-button', { 'active': isCurrentEventKey })}
onClick={decoratedOnClick}
>toggle-button</button>
);
}

const ModalNav = (props) => {
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })
    window.addEventListener("keypress", () => { 
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
    window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    }) 
}, [])
  function searchOverlay(e) {
    $("body")
        .addClass("modal-open")
        .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }

const active = props.active;
const data = useStaticQuery(graphql`
query {
    menubg: file(relativePath: { eq: "menu-bg.png" }) {
        childImageSharp {
            fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
            }
        }
     }
}
`);

useEffect(() => {
return function () {
console.log(1)
document.querySelectorAll('body,html').forEach(item => item.classList.remove('overflow'))
}
}, [])
return (
<>
<Searchpopup/>

<div className={classNames('modal-nav', { 'active': active })}>
<Container fluid>
<div className="header-top">
<Link to="/" className="logo"><Logo /></Link>
</div>
<div className="header-bottom">
<Row>
<Col lg="6" md="12">
<div className="menu-modal-wrap">

<div className="menu-enclose">
<Nav>
<Accordion defaultActiveKey="All-menus_5fc09f6729e1721c1dc7f0ba">
<ul className="parent-menu">

{props.BurgerMenu.edges.map(({ node }, key) => {
return <>
{
node.main_parent === null && node.Show_In_Burger_Menu === true && (
<>
<li>
{node.URL === "find-a-property" &&
<Link to="/property/for-sale/in-london/">{node.Label}</Link>
}
{node.URL != "find-a-property" &&
<>
{node.Secondary_URL ?
    <Link to={`${node.Secondary_URL}/`}>{node.Label}</Link>
    :<Link to={`/${node.URL}/`}>{node.Label}</Link>
  }
</>
}
<ContextAwareToggle eventKey={node.id} />
        
<Accordion.Collapse eventKey={node.id}>

<Nav className="dropdown-list">
    <ul>
        {node.URL === "find-a-property" &&
            <>
                <li><Link to="/property/for-sale/in-london/">Property for Sale</Link></li>
                <li><Link to="/property/to-rent/in-london/">Property to Rent</Link></li>
            </>
        }
        
        {props.BurgerMenu.edges.map((submenu, key2) => {
            return <>
                {submenu.node.sub_parent == null && submenu.node.main_parent && submenu.node.main_parent.Label === node.Label && submenu.node.Hide_Menu !== true &&
                    <li className="second-level">
                        {(submenu.node.Secondary_URL && submenu.node.Link_Type!="External_New_Window")?
                        <Link to={`${submenu.node.Secondary_URL}/`}>{submenu.node.Label}</Link>
                        :
                        (submenu.node.Secondary_URL && submenu.node.Link_Type == "External_New_Window")?
                        <a target="_blank" href={`${submenu.node.Secondary_URL}/`}>{submenu.node.Label}</a>
                        :
                        <Link to={`/${node.URL}/${submenu.node.URL}/`}>{submenu.node.Label}</Link>
                        }
                        <div className="open-third-level"><a className="plusminus">+</a>
                        </div>

                        <ul className="thrid-level animate__animated animate__fadeIn" style={{ display: "none" }}>

                            {props.BurgerMenu.edges.map((submenu2, key2) => {
                                return <>
                                    {submenu2.node.sub_parent !== null && submenu2.node.main_parent && submenu2.node.main_parent.Label === node.Label && submenu2.node.sub_parent && submenu2.node.sub_parent.Label === submenu.node.Label &&

                                        <li>
                                            {submenu2.node.Secondary_URL ?
                                            <Link to={`${submenu2.node.Secondary_URL}/`}>{submenu2.node.Label}</Link>
                                            :
                                            <Link to={`/${node.URL}/${submenu.node.URL}/${submenu2.node.URL}/`}>{submenu2.node.Label}</Link>
                                            }
                                        </li>

                                    }
                                </>
                            })}
                        </ul>


                    </li>

                }

            </>
        })}

    </ul>
</Nav>
</Accordion.Collapse>
</li>

</>

)
}

</>

})}
</ul>


</Accordion>
</Nav>

<GETBurgerMember />
</div>
</div>

</Col>
{!props.active && renderComponent &&
<div className="menu-side-img closing-bg d-none d-lg-block">
    <Img fluid={data.menubg.childImageSharp.fluid} alt="Menu - Anthony Pepe" />
    <div className="overlay-landing"></div>
</div>
}
</Row>
</div>
</Container>
</div>
</>
)
}

export default ModalNav
