import React, { useEffect } from 'react';


const ImgBox = ({image}) => {
    
    return (
        <div className="img-box" >
            <div className="image">                
                <img src={image} />
            </div>
        </div>
    )
}

export default ImgBox
